export const nlDict = {
  'Back to Sign In': 'Terug naar inloggen',
  'Change Password': 'Wachtwoord wijzigen',
  Changing: 'Wordt aangepast',
  Code: 'Code',
  'Confirm Password': 'Bevestig Wachtwoord',
  'Reset your Password': 'Wachtwoord resetten',
  'Confirm Sign Up': 'Bevestig inschrijving',
  'Confirm SMS Code': 'Bevestig SMS Code',
  'Confirm TOTP Code': 'Bevestig TOTP Code',
  Confirm: 'Bevestig',
  'Confirmation Code': 'Bevestigingscode',
  Confirming: 'Bevestigen',
  'Create Account': 'Account aanmaken',
  'Creating Account': 'Account wordt aangemaakt',
  Email: 'E-mail',
  'Enter your code': 'Vul je code in',
  'Enter your username': 'Vul je gebruikersnaam in',
  'Enter your phone number': 'Vul je telefoonnummer in',
  'Enter your email': 'Vul je e-mail in',
  'Forgot your password? ': 'Wachtwoord vergeten? ',
  'Hide password': 'Verberg wachtwoord',
  Loading: 'Laden',
  Username: 'Gebruikersnaam',
  'New password': 'Nieuw wachtwoord',
  Password: 'Wachtwoord',
  'Phone Number': 'Telefoonnummer',
  'Resend Code': 'Verstuur code nogmaals',
  'Reset your password': 'Reset je wachtwoord',
  'Send Code': 'Verstuur Code',
  'Send code': 'Verstuur code',
  Sending: 'Versturen',
  'Setup TOTP': 'TOTP Instellingen',
  'Show password': 'Toon wachtwoord',
  'Sign in': 'Inloggen',
  'Sign In': 'Inloggen',
  'Sign In with Amazon': 'Inloggen met Amazon',
  'Sign In with Apple': 'Inloggen met Apple',
  'Sign In with Facebook': 'Inloggen met Facebook',
  'Sign In with Google': 'Inloggen met Google',
  'Sign in to your account': 'Inloggen op je account',
  'Create a new account': 'Nieuw account aanmaken',
  'Signing in': 'Inloggen',
  Skip: 'Overslaan',
  Submit: 'Versturen',
  Submitting: 'Wordt verstuurd',
  'Verify Contact': 'Verifieer Contact',
  'Account recovery requires verified contact information':
    'Accountherstel vereist geverifieerde contactgegevens',
  Verify: 'Verifieer',
};
